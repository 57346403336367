// src/HomePage.js
import React, { useState } from 'react';
import './HomePage.css';

import TopFold from './TopFold.js';
import InvestmentG from './InvestmentG.js';
import Footer from './Footer.js';
import NewsletterCTA from './NewsletterCTA.js';


const HomePage = () => {


    return (
        <div className="cafe-homepage">
            <TopFold />
            <InvestmentG />
            <NewsletterCTA />
            <Footer />
        </div>
    );
};

export default HomePage;
