import React, { useState, useEffect } from 'react';
import './NewsletterCTA.css';

const successMessages = [
  'Success 🎉', 
  'Oh, a new email? 📥',
  'We won\'t spam you, promise! 🤞', 
  'Look at you, being all proactive! 👀'
];

const NewsletterCTA = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState(successMessages[0]);
  const [messageIndex, setMessageIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  
  useEffect(() => {
    const lastSubmitTime = localStorage.getItem('lastSubmitTime');
    if (lastSubmitTime) {
      const elapsedTime = Date.now() - lastSubmitTime;
      if (elapsedTime < 15000) {
        setIsButtonDisabled(true);
        setTimeout(() => setIsButtonDisabled(false), 15000 - elapsedTime);
      }
    }
  }, []);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const resetForm = () => {
    setIsSubmitted(false);
    setEmail('');
    setMessageIndex(0);
    setSuccessMessage(successMessages[0]);
  };

  const cycleMessages = () => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % successMessages.length;
        setSuccessMessage(successMessages[nextIndex]);
        return nextIndex;
      });
    }, 2000); // Change message every 2 seconds

    setTimeout(() => clearInterval(interval), 10000); // Stop cycling after 10 seconds
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsButtonDisabled(true);
    
    // Cycle through success messages
    cycleMessages();

    // Save the timestamp in localStorage
    localStorage.setItem('lastSubmitTime', Date.now());

    // Mailbluster API request
    const API_KEY = process.env.REACT_APP_MAILBLUSTER_KEY;
    const leadData = {
      email: email,
      subscribed: true,
      doubleOptIn: false,
      tags: ['Newsletter Subscriber']
    };

    try {
      const response = await fetch('https://api.mailbluster.com/api/leads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': API_KEY
        },
        body: JSON.stringify(leadData)
      });

      const data = await response.json();
      if (response.ok) {
        // console.log('Lead added:', data);
        setSuccessMessage('You\'re subscribed! 🎉');
      } else {
        // console.error('Error:', data.message);
        setSuccessMessage('Subscription failed. Try again.');
      }
    } catch (error) {
    //   console.error('API request failed:', error);
      setSuccessMessage('An error occurred. Please try again later.');
    }

    // Re-enable the button and reset form after 15 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
      resetForm();
    }, 15000);
  };

  return (
    <div className="newsletter-cta">
      <h1>☕ Cafe Newsletter</h1>
      <p>{'{ we will leverage '}<strong>AI and software</strong>{' to automate systems efficiently }'}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={handleInputChange}
          placeholder="Enter your email"
          required
          disabled={isSubmitted}
        />
        <button
          type="submit"
          className={isSubmitted ? 'button-success' : ''}
          disabled={isButtonDisabled}
        >
          {isSubmitted ? successMessage : 'Subscribe'}
        </button>
      </form>
    </div>
  );
};

export default NewsletterCTA;
